






























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, Ref } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartStandardSlider',
  props: {
    data: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    side: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    imperial: {
      type: Boolean
    }
  },
  setup(properties) {
    const value: number = properties.data[properties.imperial ? 'imp_avg' : 'avg'] || 0;

    const backgroundsColors: Ref<string[]> = ref(['#FF5E13', '#FFBE13', '#25C090', '#FFBE13', '#FF5E13']);
    const sides = {
      left_foot: 'left',
      right_foot: 'right'
    };

    const valuePosition = computed(() => {
      const max = properties.norms.very_high[properties.imperial ? 'imp_min' : 'min'];
      const min = properties.norms.very_low[properties.imperial ? 'imp_max' : 'max'];

      const percentvalue = ((value - min) * 100) / (max - min);
      if (percentvalue < 0) return 'calc(0% - 8px)';
      if (percentvalue > 100) return 'calc(100% - 8px)';
      return `calc(${percentvalue}% - 8px)`;
    });

    const colorStatus = computed(() => {
      switch (properties.data.range) {
        case 'neutral':
          return {
            color: '#25C090',
            background: 'rgba(37,192,144,0.1)'
          };
        case 'low':
        case 'high':
          return {
            color: '#FFBE13',
            background: 'rgba(255,190,19,0.1)'
          };
        case 'very_low':
        case 'very_high':
          return {
            color: '#FF5E13',
            background: 'rgba(255,94,19,0.1)'
          };
        default:
          return {
            color: '#25C090',
            background: 'rgba(37,192,144,0.1)'
          };
      }
    });
    return {
      // Values
      value,
      backgroundsColors,
      sides,
      // Methods
      valuePosition,
      colorStatus
    };
  }
});
