
































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useUnit } from '@/utils/unit.utils';
// Import components -------------------------------------------------------------------------------
import ElementChipNormValue from '@/components/elements/ElementChipNormValue.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningRunProfile',
  components: { ElementChipNormValue },
  props: {
    aggregates: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { isImperial } = useUnit().currentUnit();
    const { cdn } = useCDN();

    const valueType = computed(() => (isImperial.value ? 'imp_avg' : 'avg'));

    const strideLengthUnit = computed(() => getUnit(EValueTypeUnit.StrideLength));
    return { cdn, valueType, strideLengthUnit };
  }
});
