













































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, reactive, Ref } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartStandardSliderAdvanced',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    round: {
      type: Number,
      default: 0
    },
    unit: {
      type: String
    },
    imperial: {
      type: Boolean
    }
  },
  setup(properties) {
    const rLeft: Ref<HTMLCanvasElement | null> = ref(null);
    const rRight: Ref<HTMLCanvasElement | null> = ref(null);

    const backgroundsColor: Ref<string[]> = ref(['#FF5E13', '#FFBE13', '#25C090', '#FFBE13', '#FF5E13']);
    const data: Ref<any | null> = ref([]);

    const values = reactive({
      left_foot: properties.aggregates.left_foot[properties.imperial ? 'imp_avg' : 'avg'],
      right_foot: properties.aggregates.right_foot[properties.imperial ? 'imp_avg' : 'avg']
    });

    function valuePosition(value: number) {
      const max = properties.norms.very_high[properties.imperial ? 'imp_min' : 'min'];
      const min = properties.norms.very_low[properties.imperial ? 'imp_max' : 'max'];

      const percentvalue = ((value - min) * 100) / (max - min);
      if (percentvalue < 0) return 'calc(0% - 25px)';
      if (percentvalue > 100) return 'calc(100% - 25px)';
      return `calc(${percentvalue}% - 25px)`;
    }

    function colorStatus(value: string) {
      switch (value) {
        case 'neutral':
          return {
            color: '#25C090',
            background: '#EAF9F4',
            class: 'success-value'
          };
        case 'low':
        case 'high':
          return {
            color: '#FFBE13',
            background: '#FFF9E9',
            class: 'warning-value'
          };
        case 'very_low':
        case 'very_high':
          return {
            color: '#FF5E13',
            background: '#FFEFE9',
            class: 'error-value'
          };
        default:
          return {
            color: '#25C090',
            background: '#EAF9F4',
            class: 'success-value'
          };
      }
    }
    return {
      // References
      rLeft,
      rRight,
      // Values
      values,
      data,
      backgroundsColor,
      i18n,
      // Methods
      valuePosition,
      colorStatus
    };
  }
});
