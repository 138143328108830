





































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingFootProgressionAngle',
  props: {
    values: {
      type: Object,
      required: true
    },
    side: {
      type: String,
      required: false
    },
    simple: {
      type: Boolean
    }
  }
});
