






// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
import moment from 'moment';
import Highcharts from 'highcharts';
import { usePodocoreModule } from '@/plugins/podocore';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningSpeedCadenceDetailled',
  props: {
    segments: {
      type: Object,
      required: true
    },
    imperial: {
      type: Boolean
    }
  },
  setup(properties, { emit }) {
    const busModule = usePodocoreModule('bus');

    const speedUnit = computed(() => getUnit(EValueTypeUnit.Speed));

    function selectedPoints() {
      emit('selectedPoints', compareData.value);
      busModule.publish(
        busModule.events.activityRunningSelectedSegments({
          selectedSegments: compareData.value
        })
      );
    }

    const compareData: any = ref([]);

    const cadence = computed(() => {
      const data: any = [];
      properties.segments.running_step_cadence.global.avg.forEach((item: any, index: number) => {
        data.push([properties.segments.segment_duration.global.sum[index] * 1000, item || 0]);
      });

      return data;
    });

    const speed = computed(() => {
      const data: any = [];
      const running_speed =
        properties.segments.running_speed.global[properties.imperial ? 'imp_avg' : 'avg'] || [];
      running_speed.forEach((item: any, index: number) => {
        data.push([properties.segments.segment_duration.global.sum[index] * 1000, item || 0]);
      });
      return data;
    });

    const series = computed(() => {
      return [
        {
          id: 'Speed',
          name: i18n.t('commons.standards.speed'),
          data: speed.value,
          type: 'areaspline',
          color: 'var(--v-primary-base)',
          shadow: false,
          yAxis: 0,
          lineWidth: 3,
          marker: {
            symbol: 'circle',
            lineWidth: 2
          },
          zIndex: 2
        },
        {
          id: 'Cadence',
          name: i18n.t('commons.standards.cadence'),
          data: cadence.value,
          type: 'areaspline',
          color: '#6e768d',
          shadow: false,
          yAxis: 1,
          lineWidth: 3,
          marker: {
            visible: false,
            symbol: 'circle',
            lineWidth: 2
          }
        }
      ];
    });

    const plotLines: any = reactive({
      lines: {
        height: 175,
        width: 3
      },
      first: {
        index: 0,
        active: false,
        color: 'var(--v-running-darken2)',
        line: null
      },
      second: {
        index: 1,
        active: false,
        color: 'var(--v-running-base)',
        line: null
      }
    });

    const chartOptions = computed(() => {
      return {
        chart: {
          height: 250,
          events: {
            load(): any {
              const _chart = Highcharts.charts.find(
                (x: any) =>
                  x &&
                  x.options.title!.text ===
                    i18n.t('commons.standards.cadence') + ' / ' + i18n.t('commons.standards.speed')
              );
              const that: any = this;
              const speed = that.series[0].data;
              const cadence = that.series[1].data;

              speed.map((element: any, index: number) => {
                if ((element && element.y === 0) || (cadence[index] && cadence[index].y === 0)) {
                  element.update({
                    marker: {
                      enabledThreshold: 0,
                      fillColor: 'rgba(0,0,0,0)',
                      lineWidth: 0,
                      radius: 0,
                      width: 0,
                      states: {
                        hover: {
                          enabled: false
                        }
                      }
                    }
                  });
                }
              });
              cadence.map((element: any, index: number) => {
                if ((element && element.y === 0) || (speed[index] && speed[index].y === 0)) {
                  element.update({
                    marker: {
                      enabledThreshold: 0,
                      fillColor: 'rgba(0,0,0,0)',
                      lineWidth: 0,
                      radius: 0,
                      width: 0,
                      states: {
                        hover: {
                          enabled: false
                        }
                      }
                    }
                  });
                }
              });
            }
          }
        },
        title: {
          text: i18n.t('commons.standards.cadence') + ' / ' + i18n.t('commons.standards.speed'),
          style: {
            fontSize: '0'
          }
        },
        xAxis: {
          labels: {
            formatter(): any {
              const that: any = this;
              return moment.utc(that.value).format('mm:ss');
            }
          },
          crosshair: {
            width: 60,
            color: 'rgba(206,214,217,0.5)'
          }
        },
        yAxis: [
          {
            labels: {
              format: `{value}`
            },
            title: {
              text: `${i18n.t('commons.standards.speed')} ${speedUnit.value}`
            },
            min: 0,

            maxZoom: 20
          },
          {
            opposite: true,
            labels: {
              align: 'left',
              format: `{value}`
            },
            title: {
              text: i18n.t('commons.standards.cadence') + ' (' + i18n.t('commons.units.steps-min') + ')',
              margin: 20
            },

            maxZoom: 100
          }
        ],
        plotOptions: {
          areaspline: {
            lineWidth: 3,
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, 'rgba(186,204,215,0)'],
                [1, 'rgba(255,255,255,0)']
              ]
            }
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click(): any {
                  const _chart: any = Highcharts.charts.find(
                    (x: any) =>
                      x &&
                      x.options.title!.text ===
                        i18n.t('commons.standards.cadence') + ' / ' + i18n.t('commons.standards.speed')
                  );
                  const that: any = this;
                  const index = that.index;
                  const chart = _chart!.xAxis[0];

                  function drawPlotLine(type: string) {
                    // Plotline
                    if (type === 'first') {
                      removeMarkers(type);
                      removeAllPlotLines();
                    }
                    plotLines[type].line = chart.addPlotLine({
                      value: that.x,
                      color: plotLines[type].color,
                      width: plotLines.lines.width,
                      id: type
                    });
                  }
                  function drawMarker(index: number) {
                    selectedPoints();
                    const dataIndex = compareData.value[index]?.index || null;
                    if (dataIndex) {
                      _chart!.series[0].data[dataIndex].update({
                        marker: {
                          fillColor: '#ffffff',
                          lineColor: index === 0 ? 'var(--v-running-darken2)' : 'var(--v-running-base)',
                          lineWidth: 6
                        }
                      });
                    }
                  }

                  function removePlotLine(type: string) {
                    // Plotline
                    chart.removePlotLine(type);
                  }
                  function removeMarkers(type?: string) {
                    if (type !== 'first') selectedPoints();
                    _chart!.series[0].data.map((x: any) => {
                      if (x?.marker?.lineWidth) {
                        x = x.update({
                          marker: {
                            fillColor: 'var(--v-running-darken2)'
                          }
                        });
                      }
                    });
                  }

                  function removeAllPlotLines() {
                    removePlotLine('first');
                    removePlotLine('second');
                  }

                  if (
                    _chart.series[0].data[that.index].y !== 0 &&
                    _chart.series[1].data[that.index].y !== 0
                  ) {
                    // Show on chart
                    if (_chart) {
                      // Always keep two values
                      if (compareData.value.length >= 2) {
                        compareData.value.length = [];
                      }

                      // Init values text / line
                      if (
                        compareData.value.length === 0 &&
                        plotLines.first.active &&
                        plotLines.first.active
                      ) {
                        removeMarkers();
                        removeAllPlotLines();
                      }

                      // Add first values text / line
                      if (compareData.value.length === 0) {
                        drawPlotLine('first');
                      }

                      // Add second values text / line
                      if (compareData.value.length === 1) {
                        drawPlotLine('second');
                      }

                      // Add point
                      compareData.value.push({
                        index,
                        y: that.y,
                        x: that.x,
                        indexPosition: compareData.value.length
                      });

                      // Add first values marker
                      if (compareData.value.length === 1) {
                        drawMarker(0);
                      }

                      // Add second values marker
                      if (compareData.value.length === 2) {
                        drawMarker(1);
                      }

                      // If the same point is selected
                      if (
                        compareData.value.length >= 2 &&
                        compareData.value[0].index === compareData.value[1].index
                      ) {
                        removeAllPlotLines();
                        compareData.value = [];
                        removeMarkers();
                      }
                    }

                    // Sort compareData.value by Index
                    compareData.value.sort((a: any, b: any) => {
                      return a.index - b.index;
                    });
                  }
                }
              }
            },
            shadow: true,
            marker: {
              states: {
                select: {
                  enabled: false
                }
              }
            }
          }
        },
        legend: {
          useHTML: true,
          layout: 'horizontal',
          squareSymbol: false,
          symbolHeight: 4,
          symbolWidth: 16,
          symbolRadius: 2,
          symbolPadding: 10,
          itemStyle: {
            lineHeight: '20px',
            color: '#6e768d',
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontWeight: '700'
          },
          align: 'center',
          verticalAlign: 'bottom',
          floating: false,
          x: 0,
          y: 0
        },
        tooltip: {
          distance: 50,
          crosshairs: true,
          shared: true,
          followPointer: true,
          formatter() {
            const that: any = this;
            let s = `<span style="font-size: 15px;">${moment(
              properties.segments.segment_duration.global.sum[that.points[0].point.index] * 1000
            ).format('mm:ss')} min </span>`;
            s += `<br/><span>-</span><br/>`;
            for (const p of that.points) {
              const isCadence = p.series.name === i18n.t('commons.standards.cadence');
              s += `<span style="font-size: 13px; font-weight: bold; color: ${p.color}">${p.series.name} : ${
                p.point.y
              } ${isCadence ? i18n.t('commons.units.steps-min') : speedUnit.value}</span>`;
              s += `<br/>`;
            }

            return s;
          }
        },
        series: series.value
      };
    });

    watch(properties, () => {
      // Remove plot lines on values change
      const _chart = Highcharts.charts.find(
        (x: any) =>
          x &&
          x.options.title!.text ===
            i18n.t('commons.standards.cadence') + ' / ' + i18n.t('commons.standards.speed')
      );
      const chart = _chart ? _chart!.xAxis[0] : null;
      if (chart) {
        chart.removePlotLine('first');
        chart.removePlotLine('second');
      }
      compareData.value = [];
      selectedPoints();
    });

    return {
      chartOptions
    };
  }
});
